<template>
    <div id="account" class="account">
        <Header />
        <div class="container content" v-if="userInfo">
            <div class="left-container">
                <div class="userinfo">
                    <img
                        class="avatar"
                        :src="userInfo.avatar || 'https://res.peki.vip/20210105/1609843769476/1609843769477.png'"
                    />
                    <div class="username">{{ userInfo.nickname }}</div>
                </div>
                <div class="menu">
                    <div @click="toPage('/account/userInfo')" :class="$route.name === 'userInfo' ? 'active' : ''">
                        个人信息
                    </div>

                    <div @click="toPage('/account/order')" :class="$route.name === 'order' ? 'active' : ''">
                        我的订单
                    </div>
                    <div @click="onLoginOut">退出登录</div>
                </div>
            </div>
            <div class="right-container">
                <router-view></router-view>
            </div>
        </div>
        <Fotter />
    </div>
</template>
<script>
import Header from '@/components/layout/Header.vue'
import Fotter from '@/components/layout/Fotter.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'account',
    components: { Header, Fotter },
    computed: { ...mapGetters(['userInfo']) },
    methods: {
        toPage(path) {
            this.$router.push(path)
        },
        onLoginOut() {
            this.$store.dispatch('user/logout')
        },
    },
}
</script>

<style lang="scss" scoped>
#account {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .content {
        flex-grow: 1;
    }
}
.account {
    width: 100%;
    background: $theme-bg-color;
    .container {
        display: flex;
        width: $center-width;
        padding: 24px 0;
        margin: 0 auto;
    }
    .left-container {
        width: 224px;
        flex-shrink: 0;
        margin-right: 24px;
        .userinfo {
            width: 100%;
            height: 188px;
            overflow: hidden;
            background: #ffffff;
            .avatar {
                width: 80px;
                height: 80px;
                display: block;
                margin: 36px auto 20px;
                background: #ffffff;
                border-radius: 50%;
            }
            .username {
                text-align: center;
                height: 16px;
                font-size: 16px;
                color: #33312f;
                line-height: 16px;
            }
        }
        .menu {
            margin-top: 24px;
            width: 100%;
            background: #ffffff;
            > div {
                width: 100%;
                height: 64px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                color: #66625e;
                line-height: 16px;
                cursor: pointer;
            }
            .active {
                color: $theme-color;
                font-weight: 700;
            }
        }
    }
    .right-container {
        flex-grow: 1;
        background: #ffffff;
    }
}
@media screen and (max-width: 980px) and (min-width: 720px) {
    .account {
        .container {
            padding: 24px;
            width: 100%;
            box-sizing: border-box;
        }
        .left-container {
            display: none;
        }
    }
}

@media screen and (max-width: 720px) {
    .account {
        background: #ffffff;
        .container {
            padding: 0;
            width: 100%;
            box-sizing: border-box;
        }
        .left-container {
            display: none;
        }
    }
}
</style>
